import { createRouter, createWebHistory } from "vue-router";
import authGuard from "@/router/authGuard";

const routes = [
  {
    path: '/',
    redirect: 'login',
    meta: {requiresAuth: false, roles: ['administrator']},
    component: () => import('@/views/SignIn.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        meta: {requiresAuth: false, roles: ['administrator']},
        component: () => import('@/views/SignIn.vue'),
      },
    ]
  },
  {
    path: '/',
    redirect: "users",
    meta: {requiresAuth: true, roles: ['administrator']},
    children: [
      {
        path: '/users',
        name: 'users',
        meta: {requiresAuth: true, roles: ['administrator']},
        component: () => import('@/views/users/Index.vue'),
      },
      {
        path: '/create-user',
        name: 'create-user',
        meta: {requiresAuth: true, roles: ['administrator']},
        component: () => import('@/views/users/createUser.vue'),
      },
      {
        path: '/detail-user',
        name: 'detail-user',
        meta: {requiresAuth: true, roles: ['administrator']},
        component: () => import('@/views/users/detailUser.vue'),
      },
      {
        path: '/lawyer',
        name: 'lawyer',
        meta: {requiresAuth: true, roles: ['administrator']},
        component: () => import('@/views/lawyer/index.vue'),
      },
      {
        path: '/create-lawyer',
        name: 'create-lawyer',
        meta: {requiresAuth: true, roles: ['administrator']},
        component: () => import('@/views/lawyer/create.vue'),
      },
      {
        path: '/detail-lawyer',
        name: 'detail-lawyer',
        meta: {requiresAuth: true, roles: ['administrator']},
        component: () => import('@/views/lawyer/detail.vue'),
      },
      {
        path: '/primary-process',
        name: 'primary-process',
        meta: {requiresAuth: true, roles: ['administrator']},
        component: () => import('@/views/primary_process/index.vue'),
      },
      {
        path: '/create-primary-process',
        name: 'create-primary-process',
        meta: {requiresAuth: true, roles: ['administrator']},
        component: () => import('@/views/primary_process/create.vue'),
      },
      {
        path: '/detail-primary-process',
        name: 'detail-primary-process',
        meta: {requiresAuth: true, roles: ['administrator']},
        component: () => import('@/views/primary_process/detail.vue'),
      },
      {
        path: '/testimonials',
        name: 'testimonials',
        meta: {requiresAuth: true, roles: ['administrator']},
        component: () => import('@/views/testimonials/index.vue'),
      },
      {
        path: '/create-testimonials',
        name: 'create-testimonials',
        meta: {requiresAuth: true, roles: ['administrator']},
        component: () => import('@/views/testimonials/create.vue'),
      },
      {
        path: '/jobs',
        name: 'jobs',
        meta: {requiresAuth: true, roles: ['administrator']},
        component: () => import('@/views/job/index.vue'),
      },
      {
        path: '/create-jobs',
        name: 'create-jobs',
        meta: {requiresAuth: true, roles: ['administrator']},
        component: () => import('@/views/job/create.vue'),
      },
      {
        path: '/support',
        name: 'support',
        meta: {requiresAuth: true, roles: ['administrator']},
        component: () => import('@/views/support/index.vue'),
      },
      {
        path: 'support-detail',
        name: 'support-detail',
        meta: {
          requiresAuth: true,
          roles: ['administrator']
        },
        component: () => import( '@/views/support/detail.vue')
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});
router.beforeEach(authGuard);
export default router;
