import axios from 'axios';
// import router from '../router/index';

const apiUrl = process.env.VUE_APP_BASE_URL;

let header = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response) {
    console.log(error.response);
    // if (error.response.status === 401 || error.response.status === 400 || error.response.status === 404 || error.response.status === 500) {
    //   localStorage.clear();
    //   router.push('/').catch();
    // }
  }
  return Promise.reject(error);
});

const get = async function (endpoint, params = null, thereIsToken = true) {
  return new Promise((resolve) => {
    if (thereIsToken) {
      const token = localStorage.getItem('migration-admin-token');
      header = {Authorization: 'Bearer '.concat(token)};
    }
    if (params) {
      resolve(axios.get(`${apiUrl + endpoint}/${params}`, {headers: header}));
    } else {
      resolve(axios.get(apiUrl + endpoint, {headers: header}));
    }
  });
};

const post = async function (endpoint, params = null, thereIsToken = true) {
  if (thereIsToken) {
    const token = localStorage.getItem('migration-admin-token');
    header = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  }
  if (params) {
    return axios.post(apiUrl + endpoint, params, {headers: header});
  } else {
    return axios.post(apiUrl + endpoint, {}, {headers: header});
  }
};


export {post, get};
