<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
    id="sidenav-collapse-main"
    style="border-right: black dotted 1px;"
  >
    <ul class="navbar-nav">
      <li class="nav-item" >
       <div class="text-center">
         <p v-if="user" class="text-uppercase text-dark font-weight-bold mb-0">{{user.name}}</p>
         <p v-if="user" class="text-lowercase text-sm">{{user.email}}</p>
       </div>
      </li>

      <li class="nav-item" v-if="$userCan('administrator')">
        <sidenav-collapse navText="USUARIOS" :to="{ name: 'users' }">
          <template #icon>
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
            <path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"/></svg>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="$userCan('administrator')">
        <sidenav-collapse navText="ABOGADOS" :to="{ name: 'lawyer' }">
          <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M384 32H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H398.4c-5.2 25.8-22.9 47.1-46.4 57.3V448H512c17.7 0 32 14.3 32 32s-14.3 32-32 32H320 128c-17.7 0-32-14.3-32-32s14.3-32 32-32H288V153.3c-23.5-10.3-41.2-31.6-46.4-57.3H128c-17.7 0-32-14.3-32-32s14.3-32 32-32H256c14.6-19.4 37.8-32 64-32s49.4 12.6 64 32zm55.6 288H584.4L512 195.8 439.6 320zM512 416c-62.9 0-115.2-34-126-78.9c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8l95.2 163.2c5.7 9.8 9.3 21.1 6.7 32.1C627.2 382 574.9 416 512 416zM126.8 195.8L54.4 320H199.3L126.8 195.8zM.9 337.1c-2.6-11 1-22.3 6.7-32.1l95.2-163.2c5-8.6 14.2-13.8 24.1-13.8s19.1 5.3 24.1 13.8l95.2 163.2c5.7 9.8 9.3 21.1 6.7 32.1C242 382 189.7 416 126.8 416S11.7 382 .9 337.1z"/></svg>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="$userCan('administrator')">
        <sidenav-collapse navText="PROCESOS PRIMARIOS" :to="{ name: 'primary-process' }">
          <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z"/></svg>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="$userCan('administrator')">
        <sidenav-collapse navText="TESTIMONIOS" :to="{ name: 'testimonials' }">
          <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M88.2 309.1c9.8-18.3 6.8-40.8-7.5-55.8C59.4 230.9 48 204 48 176c0-63.5 63.8-128 160-128s160 64.5 160 128s-63.8 128-160 128c-13.1 0-25.8-1.3-37.8-3.6c-10.4-2-21.2-.6-30.7 4.2c-4.1 2.1-8.3 4.1-12.6 6c-16 7.2-32.9 13.5-49.9 18c2.8-4.6 5.4-9.1 7.9-13.6c1.1-1.9 2.2-3.9 3.2-5.9zM0 176c0 41.8 17.2 80.1 45.9 110.3c-.9 1.7-1.9 3.5-2.8 5.1c-10.3 18.4-22.3 36.5-36.6 52.1c-6.6 7-8.3 17.2-4.6 25.9C5.8 378.3 14.4 384 24 384c43 0 86.5-13.3 122.7-29.7c4.8-2.2 9.6-4.5 14.2-6.8c15.1 3 30.9 4.5 47.1 4.5c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176zM432 480c16.2 0 31.9-1.6 47.1-4.5c4.6 2.3 9.4 4.6 14.2 6.8C529.5 498.7 573 512 616 512c9.6 0 18.2-5.7 22-14.5c3.8-8.8 2-19-4.6-25.9c-14.2-15.6-26.2-33.7-36.6-52.1c-.9-1.7-1.9-3.4-2.8-5.1C622.8 384.1 640 345.8 640 304c0-94.4-87.9-171.5-198.2-175.8c4.1 15.2 6.2 31.2 6.2 47.8l0 .6c87.2 6.7 144 67.5 144 127.4c0 28-11.4 54.9-32.7 77.2c-14.3 15-17.3 37.6-7.5 55.8c1.1 2 2.2 4 3.2 5.9c2.5 4.5 5.2 9 7.9 13.6c-17-4.5-33.9-10.7-49.9-18c-4.3-1.9-8.5-3.9-12.6-6c-9.5-4.8-20.3-6.2-30.7-4.2c-12.1 2.4-24.7 3.6-37.8 3.6c-61.7 0-110-26.5-136.8-62.3c-16 5.4-32.8 9.4-50 11.8C279 439.8 350 480 432 480z"/></svg>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item" v-if="$userCan('administrator')">
        <sidenav-collapse navText="ALIANZAS" :to="{ name: 'jobs' }">
          <template #icon>
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M208 64a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM9.8 214.8c5.1-12.2 19.1-18 31.4-12.9L60.7 210l22.9-38.1C99.9 144.6 129.3 128 161 128c51.4 0 97 32.9 113.3 81.7l34.6 103.7 79.3 33.1 34.2-45.6c6.4-8.5 16.6-13.3 27.2-12.8s20.3 6.4 25.8 15.5l96 160c5.9 9.9 6.1 22.2 .4 32.2s-16.3 16.2-27.8 16.2H288c-11.1 0-21.4-5.7-27.2-15.2s-6.4-21.2-1.4-31.1l16-32c5.4-10.8 16.5-17.7 28.6-17.7h32l22.5-30L22.8 246.2c-12.2-5.1-18-19.1-12.9-31.4zm82.8 91.8l112 48c11.8 5 19.4 16.6 19.4 29.4v96c0 17.7-14.3 32-32 32s-32-14.3-32-32V405.1l-60.6-26-37 111c-5.6 16.8-23.7 25.8-40.5 20.2S-3.9 486.6 1.6 469.9l48-144 11-33 32 13.7z"/></svg>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
  <div class=" sidenav-footer">
    <a
      class="btn bg-gradient-success mt-4 w-100"
      @click="logout"
      type="button"
      >Cerrar sesion</a
    >
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import {decrypt} from "@/utils/encrypt";
import routerList from "@/router/index"
import {get} from "@/utils/httpRequest";
export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data : () => ({
    title: "Pagos",
    controls: "",
    isActive: "active",
    arrayOptions: [
        {key:'dashboard', name: ''},
      'users','transactions','leads', 'clients'],
    arrayMenu: [],
    user_id: null,
    user: null
  }),
  components: {
    SidenavCollapse,
  },
  mounted() {
    this.getMenu()
    this.user_id = localStorage.getItem('migration-admin-uuid')
    setTimeout(()=>{
      this.getProfile()
    },500)
  },
  methods: {
    getMenu(){
      const storage = localStorage.getItem('migration-admin-type')
      setTimeout(()=>{
        const rol = decrypt(storage)
        const router = routerList.options.routes;
        this.arrayOptions.forEach(el => {
          router.forEach(item=> {
            if(item.name === el){
              if(item.meta.roles.includes(rol)){
                this.arrayMenu.push(el)
              }
            }
          })
        })
      },500)
    },
    async getProfile(){
      if(!this.user_id) return
      const {data} = await get(`users/find-one/${this.user_id}`, null)
      data.password = ''
      this.user = data
    },
    logout() {
      localStorage.clear()
      this.$router.push('/')
    }
  },
};
</script>
